@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
/* cyrillic-ext */
/* cyrillic-ext */
// @font-face {
//   font-family: "Poppins";
//   font-style: normal;
//   font-weight: 400;
//   font-display: swap;
//   src: local("Poppins Regular"), local("Poppins-Regular"),
//     url(./../../../assets/fonts/Poppins/poppins-v15-latin-regular.woff2) format("woff2");

// }
/* poppins-regular - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: url("./../../../assets/fonts/Poppins/poppins-v15-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./../../../assets/fonts/Poppins/poppins-v15-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("./../../../assets/fonts/Poppins/poppins-v15-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./../../../assets/fonts/Poppins/poppins-v15-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */
      url("./../../../assets/fonts/Poppins/poppins-v15-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./../../../assets/fonts/Poppins/poppins-v15-latin-regular.svg#Poppins")
      format("svg"); /* Legacy iOS */
}

.mat-typography {
  font-family: "Poppins" !important;
}
.mat-h1,
.mat-h2,
.mat-h3,
.mat-h4,
.mat-h5,
.mat-h6,
h1,
h5,
h4,
strong {
  font-family: "Poppins" !important;
}
